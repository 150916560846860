import React, { useState, useEffect, useRef } from 'react';
import html2canvas from 'html2canvas';
// utils
import { sails_api } from '../../../utils/globalConstant';
import { preDevAreasSchema, postDevAreasSchema } from '../../../schemas/osdSchemas';

// components
import { StorageTypeSelection } from '../StorageTypeSelection';
import OsdsAds from '../osds-ads/OsdsAds';
import OsdResultsTable from '../components/OsdResultTable';
import TCCalculatorModal from '../tc-calculator/TCCalculatorModal';
import StormDurations from '../components/StormDurations';
import PreDevAreas from '../components/PreDevAreas';
import PostDevAreas from '../components/PostDevAreas';
import Optional from '../components/Optional';
import StormDurationsChart from '../components/StormDurationsChart';
import CalculateOsdResultButton from '../components/CalculateOsdResultButton';
import { getCompanyDetails } from '../../../utils/osdUtils';
import { recordCalculateOSD } from '../../../utils/s3Utils';
import { useCyValue } from '../../../hooks/useCyValue';
import { useSelector } from 'react-redux';
import { osdState } from '../../../utils/redux/osdStateDTSlice';
export default function ModifiedRationalTemplate({
    AEPS,
    useOSDCompany,
    useStorageType,
    setOSDMaterial,
    mapInfo,
    userInfo,
    useOSDResultsData,
    templateData,
    setTemplateData,
    setChartGenerated,
    chartRef,
    savedModel,
}) {
    const isInitialMount = useRef(true);

    /* Manufacture Data */
    const [osdCompany, setOSDCompany] = useOSDCompany;
    const [storageType, setStorageType] = useStorageType;
    const [osdResultData, setOsdResultData] = useOSDResultsData;
    const state = useSelector(osdState);

    const [tcCalculatorData, setTcCalculatorData] = useState(
        savedModel?.tcData ?? { pre: null, post: null, uncontrolled: null },
    );

    const [companyDetails, setCompanyDetails] = useState([]);
    /* Node Data */
    const [TC, setTC] = useState({
        pre: savedModel?.tcPre ?? '',
        post: savedModel?.tcPost ?? '',
        uncontrolled: savedModel?.tcU ?? '',
        psd: savedModel?.councilPSD ?? '',
    });
    // T-Duration
    const [TDuration, setTDuration] = useState(
        savedModel?.TDuration ?? {
            tDurationStepSize: 5,
            tDurationNumOfSteps: 35,
            tDurationStart: 5,
            tDurationEnd: 180,
        },
    );
    // design Storm
    const [designStorm, setDesignStorm] = useState({
        preDevDesignStorm: savedModel?.preDevDesignStorm ?? 1,
        postDevDesignStorm: savedModel?.postDevDesignStorm ?? 1,
    });
    // pre-dev
    const [preDevAreas, setPreDevAreas] = useState(
        savedModel?.preDevAreas?.areas ?? [
            {
                id: 0,
                areaName: '',
                surfaceArea: '',
                impervious: true,
                cy: 0,
            },
        ],
    );
    // post-dev
    const [postDevAreas, setPostDevAreas] = useState(
        savedModel?.postDevAreas?.areas ?? [
            {
                id: 0,
                areaName: '',
                surfaceArea: '',
                uncontrolled: '0',
                impervious: true,
                cy: 0,
            },
        ],
    );
    // cy value
    const { calculatedCy, isLoadingCyValue } = useCyValue(
        'Modified Rational Method',
        designStorm,
        mapInfo,
        { preDevAreas, setPreDevAreas },
        { postDevAreas, setPostDevAreas },
    );
    /* Optional */
    const [climateChangeRate, setClimateChangeRate] = useState(savedModel?.climateChangeRate ?? '');
    const [tankHeight, setTankHeight] = useState(savedModel?.tankHeight ?? '');

    /* Display/Loading */
    const [osdResultLoading, setOsdResultLoading] = useState(false);
    const [showTCCalculator, setShowTCCalculator] = useState('');

    /* Calculated Data */
    const sumPreDevAreas = preDevAreas.reduce((acc, area) => acc + +area.surfaceArea, 0);
    const sumPostDevAreas = postDevAreas.reduce((acc, area) => acc + +area.surfaceArea, 0);
    const sumUncontrolledAreas = postDevAreas.reduce((acc, area) => acc + +area.uncontrolled, 0);

    useEffect(() => {
        if (storageType !== '') {
            getCompanyDetails(storageType, mapInfo.rainfallStation).then((newCompanyDetails) => {
                if (!newCompanyDetails.length) {
                    setStorageType('N/A');
                    setOSDMaterial('N/A');
                }
                setCompanyDetails(newCompanyDetails);
            });
        }
    }, [storageType]);

    const computeOSDResults = async () => {
        setTemplateData(null);
        setOsdResultData(null);
        setChartGenerated(false);
        setOsdResultLoading(true);

        const { data: parsedPreDevAreas } = preDevAreasSchema.safeParse({
            areas: preDevAreas,
        });

        const { data: parsedPostAreas } = postDevAreasSchema.safeParse({
            areas: postDevAreas,
        });

        const osdRoute = '/OSD/osdResults/OsdModifiedRational';
        const osdPayload = {
            method: 'Modified Rational Method',
            psd: TC.psd,
            councilPSD: TC.psd,
            preDevAep: designStorm.preDevDesignStorm,
            postDevAep: designStorm.postDevDesignStorm,
            preDevAreas: parsedPreDevAreas,
            postDevAreas: parsedPostAreas,
            tcPre: TC.pre,
            tcPost: TC.post,
            tcU: TC.uncontrolled,
            tankHeight: tankHeight,
            climateAdjustment: 1 + climateChangeRate / 100,
            tDurationStepSize: TDuration.tDurationStepSize,
            tDurationNumOfSteps: TDuration.tDurationNumOfSteps,
            tDurationStart: TDuration.tDurationStart,
            deltaT: state.deltaT,
        };

        await sails_api
            .post(osdRoute, {
                latitude: mapInfo.coordinate.lat,
                longitude: mapInfo.coordinate.lng,
                osd: osdPayload,
                totalImpArea: +sumPostDevAreas,
                totalPArea: 0,
            })
            .then((res) => {
                if (res.data.message) alert(res.data.message);
                else {
                    setOsdResultData(res.data);
                    recordCalculateOSD({
                        name: userInfo.assessorName,
                        email: userInfo.assessorEmail,
                        coordinates: `${mapInfo.coordinate.lat}, ${mapInfo.coordinate.lng}`,
                        catchment_area: sumPostDevAreas,
                        council: mapInfo.council,
                        address: mapInfo.address,
                        storage_type: storageType,
                        tank_specified: osdCompany,
                        psd: res.data.q_pre,
                        volume: res.data.vreq_n_max,
                        height_above_orifice: tankHeight,
                        orifice_diameter: res.data.report?.orifice?.orificeDiameter,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                alert('Computing OSD result error!');
            });

        /* Generate Storm Duration Chart */
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        let chartDataBuf;
        if (chartRef.current) {
            await delay(1500); // Add a delay of 1500ms to ensure the chart is fully rendered
            await html2canvas(chartRef.current)
                .then((canvas) => {
                    const image = canvas.toDataURL('image/png');
                    chartDataBuf = image;
                    setChartGenerated(true);
                })
                .catch((error) => {
                    console.error('Error capturing chart as image:', error);
                });
        } else {
            console.error('chartRef is null');
        }

        setOsdResultLoading(false);
        setTemplateData({
            ...osdPayload,
            hasTcPre: TC.pre,
            hasTcPost: TC.post,
            hasTcU: TC.uncontrolled,
            tcData: tcCalculatorData,
            preDevDesignStorm: designStorm.preDevDesignStorm,
            postDevDesignStorm: designStorm.postDevDesignStorm,
            climateChangeRate: climateChangeRate ? climateChangeRate : 'N/A',
            sumPreDevAreas: sumPreDevAreas,
            sumPostDevAreas: sumPostDevAreas,
            sumUncontrolledAreas: sumUncontrolledAreas,
            tDurChart: chartDataBuf,
            TDuration,
        });
    };

    const downloadChartAsImage = () => {
        const link = document.createElement('a');
        link.href = templateData.tDurChart;
        link.download = 'storm_durations_chart.png';
        link.click();
    };

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }
        const resetOSDData = () => {
            setOsdResultData(null);
            setTemplateData(null);
        };

        resetOSDData();
    }, [TDuration, postDevAreas, preDevAreas, designStorm, climateChangeRate, tankHeight]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '1.5%',
                }}
            >
                <div style={{ width: '75%' }}></div>
            </div>
            <StormDurations
                TDuration={TDuration}
                setTDuriation={setTDuration}
                designStorm={designStorm}
                setDesignStorm={setDesignStorm}
                AEPS={AEPS}
            />
            <br />
            {/* Pre-Development Areas */}
            <PreDevAreas
                preDevAreas={preDevAreas}
                setPreDevAreas={setPreDevAreas}
                TC={TC}
                setTC={setTC}
                sumPreDevAreas={sumPreDevAreas}
                sumPostDevAreas={sumPostDevAreas}
                setShowTCCalculator={setShowTCCalculator}
                calculatedCy={calculatedCy}
                options={{
                    qudm: true,
                }}
            />
            <br />
            {/* Post-Development Areas */}
            <PostDevAreas
                postDevAreas={postDevAreas}
                setPostDevAreas={setPostDevAreas}
                TC={TC}
                setTC={setTC}
                sumPreDevAreas={sumPreDevAreas}
                sumPostDevAreas={sumPostDevAreas}
                setShowTCCalculator={setShowTCCalculator}
                calculatedCy={calculatedCy}
            />
            <br />
            {/* optional */}
            <Optional
                climateChangeRate={climateChangeRate}
                setClimateChangeRateChange={setClimateChangeRate}
                tankHeight={tankHeight}
                setTankHeight={setTankHeight}
            />
            <br />
            {/* OSD Material */}
            <div>
                <StorageTypeSelection setStorageType={setStorageType} />
            </div>

            {storageType && (
                <>
                    <br />
                    <OsdsAds
                        companyDetails={companyDetails}
                        setOSDMaterial={setOSDMaterial}
                        osdCompany={osdCompany}
                        setOSDCompany={setOSDCompany}
                        storageType={storageType}
                    />
                </>
            )}
            <br />
            {/* Calculate OSD Result Button */}
            <CalculateOsdResultButton
                TC={TC}
                preDevAreas={preDevAreas}
                postDevAreas={postDevAreas}
                tankHeight={tankHeight}
                storageType={storageType}
                osdCompany={osdCompany}
                computeOSDResults={computeOSDResults}
                osdResultLoading={osdResultLoading}
                disabled={
                    (!TC.psd && +sumPreDevAreas - +sumPostDevAreas !== 0) ||
                    (tankHeight !== '' && +tankHeight === 0)
                }
                userInfo={userInfo}
            />

            {/* Results Table */}
            {osdResultData && (
                <>
                    <br />
                    <OsdResultsTable
                        preDevDesignStorm={designStorm.preDevDesignStorm}
                        postDevDesignStorm={designStorm.postDevDesignStorm}
                        tableData={{
                            aboveGroundStorage: {
                                permissibleDischarge: TC.psd
                                    ? TC.psd
                                    : parseFloat(osdResultData.report.q_pre).toFixed(2),
                                onSiteDetentionVolume: (
                                    osdResultData.report.vreq_n_max *
                                    (1 + climateChangeRate / 100)
                                ).toFixed(2),
                                orificeDiameter:
                                    osdResultData.report.orifice.orificeDiameter === 'N/A' ||
                                    osdResultData.report.orifice.orificeDiameter === null ||
                                    parseFloat(
                                        +osdResultData.report.orifice.orificeDiameter,
                                    ).toFixed(2) === '0.00'
                                        ? 'N/A'
                                        : parseFloat(
                                              +osdResultData.report.orifice.orificeDiameter,
                                          ).toFixed(2) + ' mm',
                            },
                        }}
                    />
                    <br />
                    <StormDurationsChart
                        chartRef={chartRef}
                        osdResultData={osdResultData.report}
                        downloadChartAsImage={downloadChartAsImage}
                    />
                </>
            )}
            <TCCalculatorModal
                type={showTCCalculator}
                tcCalculatorData={tcCalculatorData[showTCCalculator]}
                onClose={() => setShowTCCalculator('')}
                onDone={(tcCalculatorData, finalResult) => {
                    setTC((prev) => ({ ...prev, [showTCCalculator]: finalResult }));
                    setTcCalculatorData((prev) => ({
                        ...prev,
                        [showTCCalculator]: tcCalculatorData,
                    }));
                }}
            />
            <br />
        </>
    );
}
