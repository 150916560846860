import React from 'react';
import { StyleSheet, View, Page, Text, Link, Image } from '@react-pdf/renderer';

import ReportFooter from '../ReportFooter';
import ReportHeader from '../ReportHeader';

import Table from '../Table';
import { TCCalculatorTemplate } from '../tc-calculation/TCCalculatorTemplate';

import tcImage from '../../../assets/tooltip-images/tc.png';
import { createStyles, defaultStyles } from '../common';
import PSDCalculations from './PSDCalculations';
import { round } from '../../common';

const preDevFlowData = (data) => {
    const climateChangeScenario = `${data.osd.ifd.scenario ? `${data.osd.ifd.scenario} ` : ''}${data.osd.ifd.term}`;
    return [
        {
            containerStyles: {
                width: '60%',
                margin: '0 auto',
            },
            columns: ['Catchment Name', 'Area (m²)'],
            items: [
                ...data.osd.preDevAreas.areas.map((obj) => {
                    return [obj.areaName, obj.surfaceArea];
                }),
                ['Total Catchment Area (m²)', data.osd.sumPreDevAreas],
            ],
            styles: createStyles({
                ...defaultStyles,
                tableWidth: '60%',
            }),
            cellWidths: ['66.6%', '33.3%'],
            title: 'A (Total Catchment Area, m²)',
        },
        {
            columns:
                data.osd.deltaT > 1 || data.osd.climateAdjustment > 1
                    ? [
                          `Intensity is obtained by applying Climate Change scenario [${climateChangeScenario}] to BOM IFD 2016 data* `,
                      ]
                    : (data.osd.deltaT !== 0 && data.osd.climateAdjustment === 1) ||
                        data.osd.climateAdjustment === 0
                      ? ['Intensity is obtained from latest BOM IFD 2016 data as follows']
                      : [
                            `Intensity is obtained by applying Climate Change scenario [${climateChangeScenario}] to BOM IFD 2016 data* `,
                        ],

            items: [
                data.osd.climateAdjustment === 1
                    ? [
                          'Latitude, degrees',
                          'Longitude, degrees',
                          'Catchment Time of Concentration, min',
                          'AEP,%',
                          'Intensity, mm/hr',
                      ]
                    : [
                          'Catchment Time of Concentration, min',
                          'AEP,%',
                          'Intensity, mm/hr',
                          'Climate Change Scenario* ',
                          'Temperature Increase, Degree Celsius* ',
                          'Rate of Change, %/Degree Celsius*',
                          'Climate Change Adusted Intensity, mm/hr* ',
                      ],
                ,
                data.osd.climateAdjustment === 1
                    ? [
                          data.latitude,
                          data.longitude,
                          data.osd.catchmentTimeTc,
                          data.osd?.aepPSD,
                          round(data.osdResult?.i_PSD),
                      ]
                    : [
                          data.osd.catchmentTimeTc,
                          data.osd?.aepPSD,
                          round(data.osdResult?.i_PSD),
                          climateChangeScenario,
                          round(+data.osd.deltaT),
                          round(+data.osd.climateChangeRate),
                          round(+data.osd.climateAdjustment * data.osdResult?.i_PSD),
                      ],
            ],
            styles: createStyles({
                ...defaultStyles,
                headerBorder: false,
                headerTextAlign: 'left',
            }),
            cellWidths:
                data.osd.climateAdjustment === 1
                    ? ['20%', '20%', '20%', '20%', '20%']
                    : ['14.286%', '14.286%', '14.286%', '14.286%', '14.286%', '14.286%', '14.286%'],
            headerWidths: ['100%'],
            title: 'I (Design Storm Intensity, mm/hr)',
            footer:
                data.osd.climateAdjustment === 1
                    ? null
                    : '*Note: Climate change adjusted intensities are calculated using method outline in AR&R Book1 Chapter 6. More info on climate change adjusted intensities is found in “Climate Change Adjustment” section 7. N/A means no climate change adjustment is applied.',
        },
        {
            containerStyles: {
                width: '80%', // Limits the table to 80% of the available space
                margin: '0 auto', // Centers the table
            },
            columns: ['Catchment Name', 'Area (m²)', 'C - Value (1)', 'Weighted C - Value (e)'],
            items: [
                ...data.osdResult.preDevAreas.map((obj) => {
                    return [
                        obj.areaName,
                        obj.surfaceArea,
                        round(obj.runoffCoefficient, 2),
                        round(obj.weighted_c, 2),
                    ];
                }),
            ],
            styles: createStyles({
                ...defaultStyles,
                tableWidth: '80%',
            }),
            cellWidths: ['31.25%', '31.25%', '31.25%', '31.25%'],
            title: 'C (Total Weighted C - Value)',
        },
        {
            containerStyles: {
                width: '60%',
                margin: '0 auto',
            },
            columns: ['', ''],
            items: [['Total Weighted C-Value (f):', round(data.osdResult.Cpre, 2)]],
            styles: createStyles({
                ...defaultStyles,
                tableWidth: '100%',
                borderStyle: 'none',
            }),
            cellWidths: ['30%', '10%'],
        },
    ];
};

const postDevFlowData = (data) => {
    const climateChangeScenario = `${data.osd.ifd.scenario ? `${data.osd.ifd.scenario} ` : ''}${data.osd.ifd.term}`;
    return [
        {
            columns: ['Catchment Name', 'Area (m²)'],
            items: [
                ...data.osd.postDevAreas.areas.map((obj) => {
                    return [obj.areaName, obj.surfaceArea];
                }),
                ['Total Catchment Area (m²)', data.osd.sumPostDevAreas],
            ],
            styles: createStyles({
                ...defaultStyles,
                tableWidth: '60%',
            }),
            cellWidths: ['66.6%', '33.3%'],
            title: 'A (Total Catchment Area, m²)',
        },
        {
            columns:
                data.osd.climateAdjustment === 1
                    ? ['Intensity is obtained from latest BOM IFD 2016 data as follows']
                    : [
                          `Intensity is obtained by applying Climate Change scenario [${climateChangeScenario}] to BOM IFD 2016 data* `,
                      ],
            items: [
                data.osd.climateAdjustment === 1
                    ? [
                          'Latitude, degrees',
                          'Longitude, degrees',
                          'Catchment Time of Concentration, min',
                          'AEP,%',
                          'Intensity, mm/hr',
                      ]
                    : [
                          'Catchment Time of Concentration, min',
                          'AEP,%',
                          'Intensity, mm/hr',
                          'Climate Change Scenario* ',
                          'Temperature Increase, Degree Celsius* ',
                          'Rate of Change, %/Degree Celsius*',
                          'Climate Change Adusted Intensity, mm/hr* ',
                      ],
                ,
                data.osd.climateAdjustment === 1
                    ? [
                          data.latitude,
                          data.longitude,
                          data.osd.catchmentTimeTc,
                          data.osd?.aepOSD,
                          round(data.osdResult?.i_OSD),
                      ]
                    : [
                          data.osd.catchmentTimeTc,
                          data.osd?.aepOSD,
                          round(data.osdResult?.i_OSD),
                          climateChangeScenario,
                          round(+data.osd.deltaT),
                          round(+data.osd.climateChangeRate),
                          round(+data.osd.climateAdjustment * data.osdResult?.i_OSD),
                      ],
            ],
            styles: createStyles({
                ...defaultStyles,
                headerBorder: false,
                headerTextAlign: 'left',
            }),
            cellWidths:
                data.osd.climateAdjustment === 1
                    ? ['20%', '20%', '20%', '20%', '20%']
                    : ['14.286%', '14.286%', '14.286%', '14.286%', '14.286%', '14.286%', '14.286%'],
            headerWidths: ['100%'],
            title: 'I (Design Storm Intensity, mm/hr)',
            footer:
                data.osd.climateAdjustment === 1
                    ? null
                    : '*Note: Climate change adjusted intensities are calculated using method outline in AR&R Book1 Chapter 6. More info on climate change adjusted intensities is found in “Climate Change Adjustment” section 7. N/A means no climate change adjustment is applied.',
        },
        {
            columns: ['Catchment Name', 'Area (m²)', 'C - Value (1)', 'Weighted C - Value (e)'],
            items: [
                ...data.osdResult.postDevAreas.map((obj) => {
                    return [
                        obj.areaName,
                        obj.surfaceArea,
                        round(obj.runoffCoefficient, 2),
                        round(obj.weighted_c, 2),
                    ];
                }),
            ],
            styles: createStyles({
                ...defaultStyles,
                tableWidth: '80%',
            }),
            cellWidths: ['31.25%', '31.25%', '31.25%', '31.25%'],
            title: 'C (Total Weighted C - Value)',
        },
        {
            containerStyles: {
                width: '60%',
                margin: '0 auto',
            },
            columns: ['', ''],
            items: [['Total Weighted C-Value (f):', round(data.osdResult.Cpost, 2)]],
            styles: createStyles({
                ...defaultStyles,
                tableWidth: '100%',
                borderStyle: 'none',
            }),
            cellWidths: ['30%', '10%'],
        },
    ];
};

const DesignCalculationDetails = ({ data }) => {
    const styles = StyleSheet.create({
        body: {
            paddingTop: 30,
            paddingBottom: 30,
            paddingHorizontal: 35,
            marginBottom: 10,
            fontSize: 11,
        },
        section: {
            marginBottom: 10,
            padding: 15,
            display: 'flex',
            flexDirection: 'row',
        },
        mainTitle: {
            fontSize: 17,
            fontweight: 1000,
            textAlign: 'center',
            marginTop: 15,
            marginBottom: 15,
        },
        subtitle: {
            fontSize: 14,
            fontweight: 400,
            marginTop: 10,
            marginBottom: 10,
        },
        text: {
            marginTop: 5,
            marginBottom: 5,
            fontSize: 10,
        },
        catchmentTimes: {
            marginTop: 10,
            fontSize: 15,
            width: '100%',
            justifyContent: 'end',
            display: 'flex',
        },
    });

    return (
        <Page style={styles.body} wrap>
            <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />
            <View style={{ pageBreakAfter: 'always' }}>
                <Text style={styles.mainTitle}>OSD Design Calculation Details</Text>
            </View>

            <View>
                <Text style={styles.subtitle}>
                    About Swinburne Method (
                    {data.osd.qaMultiplicationFactor === '2' ? 'OSD4W' : 'Standard'} variation)
                </Text>
                <Text style={styles.text}>
                    The Swinburne Method is outlined by Dr. Donald Ian Phillips in this{' '}
                    <Link src="https://largefilehosting.s3.ap-northeast-1.amazonaws.com/ProjectSharing/Update%20Blogpost%20Link/A%20generic%20method%20for%20OSD%20detention.pdf">
                        paper
                    </Link>{' '}
                    published in 1995. Further analysis shows calculation methods for{' '}
                    <Link src="https://sear.unisq.edu.au/29186/1/Cook_G_Brodie.pdf">
                        3 different storage types
                    </Link>{' '}
                    which are used to generate results in this report. The calculation methods have
                    been compared and validated against the OSD4w software (legacy tool used for
                    Swinburne calculations in Victoria, Australia). It is important to clarify:
                </Text>
                <Text style={[styles.text, { marginLeft: 20 }]}>
                    OSD4w software developers have introduced a “safety parameter” in the
                    calculations, therefore the results produced by OSD4w are not the same as the
                    results produced using the standard Swinburne method devised by Dr. Phillips.
                </Text>
                <Text style={styles.text}>
                    OSD4w software has been used for a long time and, unfortunately, the developer
                    has passed. This legacy software uses 1987 IFD data and is not inline (no longer
                    maintained) with latest AR&R guidance to use 2016 IFD data. The following
                    calculations in this report are generated by OSDs For VIPs’ Swinburne method
                    (OSD4w variation). The calculations use 2016 IFD data as per AR&R guidance.
                    Calculation details are outlined in the following sections.
                </Text>
            </View>
            <View>
                <Text style={styles.subtitle}>
                    Section 1: Determine catchment time of concentration (Tc), time of catchment to
                    site (Tcs) & time of site to outlet (Tso)
                </Text>
                <Text style={styles.text}>
                    Swinburne Method uses the position of the development site within a larger
                    catchment to determine permissible discharge. This is different from other
                    methods, as for the Swinburne method the time of concentration on the site for
                    both “pre-development” and “post-development” site condition is not relevant
                    This requires establishment of Tc, Tcs & Tso for the catchment in which the
                    development is located, as in below figure:
                </Text>
                <Image src={tcImage} height={100} width={100}></Image>
            </View>

            <View wrap>
                <Text style={[styles.text, { marginTop: 10, marginBottom: 0 }]}>
                    Time of Concentration are calculated as follows:
                </Text>

                {!data.osd.tcData['catchmentTimeTCS'] && (
                    <Text style={[styles.text, { marginTop: 10 }]}>
                        Tc = {data.osd.catchmentTimeTcs} minutes (specified by engineer)
                    </Text>
                )}
                <TCCalculatorTemplate
                    tcData={data.osd.tcData}
                    devType={['catchmentTimeTCS', 'catchmentTimeTSO']}
                    createStyles={createStyles}
                    defaultStyles={defaultStyles}
                    headers={{
                        catchmentTimeTCS: 'Time of catchment to site (Tcs):',
                        catchmentTimeTSO: 'Time of site to outlet (Tso):',
                    }}
                />
                {!data.osd.tcData['catchmentTimeTSO'] && (
                    <Text style={styles.text}>
                        Tso = {data.osd.catchmentTimeTso} minutes (specified by engineer)
                    </Text>
                )}
                <Text style={[styles.text, { textDecoration: 'underline', marginTop: 10 }]}>
                    Catchment Time of Concentration (Tc)
                </Text>
                <Text style={[styles.text, { marginLeft: 20 }]}>
                    Tc = Tcs + Tso = {data.osd.catchmentTimeTcs} + {data.osd.catchmentTimeTso} ={' '}
                    {data.osd.catchmentTimeTc}
                </Text>
            </View>

            {/* Section 2: Determine Catchment Post Development Flow*/}
            <View wrap>
                <Text style={styles.subtitle}>
                    Section 2: Determine Catchment Pre Development Flow{' '}
                </Text>
                {data.osd.psd ? (
                    <Text style={styles.text}>
                        The PSD used in this report has been provided by the Consultant. There is no
                        need to calculate pre development flows.
                    </Text>
                ) : (
                    <View wrap>
                        <Text style={styles.text}>
                            Catchment Pre development flow is determined using Rational Method
                            where:{' '}
                        </Text>
                        {preDevFlowData(data).map((table, index) => (
                            <>
                                {table.styles ? (
                                    <Table
                                        tableKey={index}
                                        columnNames={table.columns}
                                        tableData={table.items}
                                        headerWidths={table.headerWidths}
                                        cellWidths={table.cellWidths}
                                        customCellWidths={table.customCellWidths}
                                        tableStyles={table.styles}
                                        title={table.title}
                                        footer={table.footer}
                                    />
                                ) : (
                                    <></>
                                )}
                            </>
                        ))}
                        {/* <NotesSection /> */}
                        <Text style={[styles.text, { textDecoration: 'underline', marginTop: 15 }]}>
                            Pre Development Flow Calculation
                        </Text>
                        <Text style={[styles.text, { marginLeft: 20 }]}>
                            Pre Development Flow (Qp), L/s = C * I * A / 3600
                        </Text>
                        <Table
                            tableKey="Pre Development Flow Calculation"
                            columnNames={[
                                'A (Total Catchment Area, m²)',
                                'I (Design Storm Intensity, mm/hr)',
                                'C (Total Weighted C - Value)',
                                'Qp, L/s',
                            ]}
                            tableData={[
                                [
                                    data.osd.sumPreDevAreas,
                                    round(data.osdResult.i_PSD, 2),
                                    round(data.osdResult.Cpre, 2),
                                    round(data.osdResult.Qp, 2),
                                ],
                            ]}
                            cellWidths={['31.25%', '31.25%', '31.25%', '31.25%']}
                            tableStyles={createStyles({
                                ...defaultStyles,
                                marginTop: 0,
                                tableWidth: '80%',
                            })}
                        />
                    </View>
                )}
            </View>

            {/* {Section 3} */}
            <View wrap style={{ marginTop: 20 }}>
                <Text style={styles.subtitle}>
                    Section 3: Determine Catchment Post Development Flow
                </Text>
                <Text style={styles.text}>
                    Catchment Post development flow is determined using Rational Method where:
                </Text>
                {postDevFlowData(data).map((table, index) => (
                    <>
                        {table.styles ? (
                            <Table
                                tableKey={index}
                                columnNames={table.columns}
                                tableData={table.items}
                                headerWidths={table.headerWidths}
                                cellWidths={table.cellWidths}
                                customCellWidths={table.customCellWidths}
                                tableStyles={table.styles}
                                title={table.title}
                                footer={table.footer}
                            />
                        ) : (
                            <></>
                        )}
                    </>
                ))}
                {/* <NotesSection /> */}
                <Text style={[styles.text, { textDecoration: 'underline', marginTop: 15 }]}>
                    Post Development Flow Calculation
                </Text>
                <Text style={[styles.text, { marginLeft: 20 }]}>
                    Post Development Flow (Qp), L/s = C * I * A / 3600
                </Text>
                <Table
                    tableKey="Post Development Flow Calculation"
                    columnNames={[
                        'A (Total Catchment Area, m²)',
                        'I (Design Storm Intensity, mm/hr)',
                        'C (Total Weighted C - Value)',
                        'Qa, L/s',
                    ]}
                    tableData={[
                        [
                            data.osd.sumPostDevAreas,
                            round(data.osdResult.i_OSD, 2),
                            round(data.osdResult.Cpost, 2),
                            round(data.osdResult.Qa, 2),
                        ],
                    ]}
                    cellWidths={['31.25%', '31.25%', '31.25%', '31.25%']}
                    tableStyles={createStyles({
                        ...defaultStyles,
                        marginTop: 0,
                        tableWidth: '80%',
                    })}
                />
            </View>

            {/* Section 4 */}
            <View wrap style={{ marginTop: 20 }}>
                <Text style={styles.subtitle}>
                    Section 4: Determine Permissible Site Discharge (PSD)
                </Text>
                {data.osd.psd ? (
                    <Text style={styles.text}>
                        The PSD used in this report has been provided by the Engineer.
                    </Text>
                ) : (
                    <View wrap>
                        <Text style={styles.text}>
                            PSD is determined by solving Swinburne Method equation for{' '}
                            {data.storageType}
                        </Text>
                        <PSDCalculations storageType={data.storageType} />
                    </View>
                )}
            </View>

            {/* Section 5 */}
            <View wrap style={{ marginTop: 20 }}>
                <Text style={styles.subtitle}>Section 5: Determine Storage Volume Required</Text>
                <View wrap>
                    <Text style={styles.text}>
                        Storage volume is determined by solving Swinburne Method equation for
                        {data.storageType} using various trials of duration Td to find max volume
                        required (see section 6 for iterations).
                    </Text>
                    <PSDCalculations storageType={data.storageType} />
                </View>
            </View>

            <ReportFooter />
        </Page>
    );
};

export default DesignCalculationDetails;

const NotesSection = (intensity, aep) => {
    const styles = StyleSheet.create({
        text: {
            marginTop: 5,
            fontSize: 10,
        },
    });
    return (
        <View style={{ marginTop: 15 }}>
            <Text style={[styles.text, { marginBottom: 5 }]}>Notes:</Text>
            <Text style={styles.text}>
                (a) C – Value refers to a coefficient of discharge (also known as Cy, calculated
                using QUDM & AR&R method) or volumetric runoff coefficient (also known as Cv,
                typically estimated by Engineer or referenced by Guidelines like IDM Victoria). They
                are functionally similar within the rational method equation (difference is the
                methodology used to determine these values.). It is recommended that the same
                methodology is used for all catchments to ensure consistency.
            </Text>
            <Text style={styles.text}>
                (b) Only used in calculation of Cy values (see QUDM page)
            </Text>
            <Text style={styles.text}>
                (c) A coefficient of discharge (Cy) calculated using QUDM & AR&R method (see QUDM
                page)
            </Text>
            <Text style={styles.text}>(d) C – Value specified by engineer.</Text>
            <Text style={styles.text}>
                (e) This is a weighted average that represents the catchment’s contribution to the
                total C-Value of the development
            </Text>
            <Text style={styles.text}>
                (f) This is the total C-Value used to represent the development in the rational
                method calculation.
            </Text>

            <Table
                tableKey="NotesTable"
                columnNames={['Intensity @ 60 min duration for 10% AEP, I10', 'intensity']}
                tableData={[['Pervious 10% AEP runoff coefficient, C10', 'aep']]}
                cellWidths={['70%', '30%']}
                tableStyles={createStyles({
                    ...defaultStyles,
                    tableWidth: '60%',
                })}
            />
        </View>
    );
};
