import React from 'react';
import { StyleSheet, View, Page, Text } from '@react-pdf/renderer';

import ReportFooter from '../ReportFooter';
import ReportHeader from '../ReportHeader';
import Table from '../Table';
import { createStyles, defaultStyles } from '../common';
import { round } from '../../common';

// using https://lingojam.com/SuperscriptGenerator to generate superscripts (<sup>2</sup> is not accept by React PDF)

const getClimatePercentage = (num) => {
    if (num === 1) {
        return 'No climate change adjustment';
    } else if (num < 1) {
        return '-' + round((1 - num) * 100) + '%';
    } else {
        return '+' + round((num - 1) * 100) + '%';
    }
};

function getAdjustedStorageVolume(num, adjustment) {
    return round(num * adjustment);
}

const generateAllTableData = (data) => {
    console.log({ data });
    return [
        !data.osd.psd
            ? {
                  columns: [
                      'Pre-Development Site Details',
                      'Area (m²)',
                      'Runoff Coefficient',
                      'Uncontrolled Area (m²)',
                  ],
                  items: data.osd.preDevAreas.areas.map((obj) => {
                      return [obj.areaName, obj.surfaceArea, obj.runoffCoefficient, 'N/A'];
                  }),
                  styles: createStyles(),
                  cellWidths: ['40%', '20%', '20%', '20%'],
                  headerWidths: ['40%', '20%', '20%', '20%'],
              }
            : {},

        {
            columns: [
                'Post-Development Site Details',
                'Area (m²)',
                'Runoff Coefficient',
                'Uncontrolled Area (m²)',
            ],
            items: data.osd.postDevAreas.areas.map((obj) => {
                return [obj.areaName, obj.surfaceArea, obj.runoffCoefficient, obj.uncontrolled];
            }),
            styles: createStyles(),
            cellWidths: ['40%', '20%', '20%', '20%'],
            headerWidths: ['40%', '20%', '20%', '20%'],
        },
        {
            columns: ['Storm Details'],
            items: [
                ['AEP for PSD Calc (%)', data.osd.aepPSD],

                ['AEP for PSD Storage (%)', data.osd.aepOSD],
                ['Intensity For PSD Calc (mm/hr)', round(data.osdResult.i_PSD, 2)],
                ['Intensity For Storage Calc (mm/hr)', round(data.osdResult.i_OSD, 2)],
            ],
            styles: createStyles({
                ...defaultStyles,
                headerFontSize: 13,
                headerTextAlign: 'left',
                tableWidth: '60%',
                headerBorder: false,
            }),
            cellWidths: ['66.6%', '33.3%'],
            headerWidths: ['100%'],
        },
        {
            columns: ['C-Value'],
            items: [
                ['Pre Dev', round(data.osdResult.Cpre, 2)],

                ['Post Dev Controlled', round(data.osdResult.Cpost, 2)],
                ['Post Dev Uncontrolled', round(data.osdResult.Cu, 2)],
            ],
            styles: createStyles({
                ...defaultStyles,
                headerFontSize: 13,
                headerTextAlign: 'left',
                tableWidth: '60%',
                headerBorder: false,
            }),
            cellWidths: ['66.6%', '33.3%'],
            headerWidths: ['100%'],
        },
        {
            columns: ['Flow and Volume'],
            items: [
                [
                    'Time of Concentration of catchment to site outlet (Tcs):',
                    data.osd.catchmentTimeTcs + ' mins',
                ],
                [
                    'Time of Concentration of site outlet to catchment outlet (Tso):',
                    data.osd.catchmentTimeTso + ' mins',
                ],
                ['Time of Concentration of catchment (Tc):', data.osd.catchmentTimeTc + ' mins'],
                ['Pre-Dev peak site inflow (L/s):', round(data.osdResult.Qp, 2)],
                ['Permissible Site Discharge, PSD (L/s):', round(data.osdResult.PSD)],
                // ['Uncontrolled peak site inflow (L/s):', round(data.osdResult.Qu)],
                ['Post-Dev Peak Site Inflow for PSD (L/s):', round(data.osdResult.Qa, 2)],
                ['Critical Duration, Td (min):', round(data.osdResult.td)],
                ['Post-Dev Peak Site Inflow Based on Td (L/s):', round(data.osdResult.Qa_dash, 2)],
                // ['Post-Dev peak site inflow for OSD (L/s):', round(data.osdResult.Qa_dash)],
                // [
                //     'Calculated PSD (L/s):',
                //     data.osd.psd === false ? round(data.osdResult.PSD) : 'N/A',
                // ],
                // ['Nominated PSD (L/s):', round(data.osdResult.PSD)],
                ['Required Storage Volume (m³)', round(data.osdResult.onSiteDetentionVolume, 2)],
                data.osd.climateAdjustment !== 1
                    ? [
                          'Climate Change (% increase of required storage volume):',
                          getClimatePercentage(parseFloat(data.osd.climateAdjustment)),
                      ]
                    : [],
                data.osd.climateAdjustment !== '1'
                    ? [
                          'Adjusted Required Storage Volume (m³):',
                          getAdjustedStorageVolume(
                              data.osdResult.onSiteDetentionVolume,
                              data.osd.climateAdjustment,
                          ),
                      ]
                    : [],
                data.osdResult.orificeDiameter !== 'N/A'
                    ? ['Height of storage above orifice (m):', round(data.osd.tankHeight)]
                    : [],

                data.osdResult.orificeDiameter !== 'N/A'
                    ? ['Required Orifice Diameter (mm):', round(data.osdResult.orificeDiameter)]
                    : [],
            ],
            cellWidths: ['75%', '25%'],
            headerWidths: ['100%'],
            styles: createStyles({
                ...defaultStyles,
                textAlignment: 'center',
                headerBorder: false,
                tableWidth: '80%',
                headerFontSize: 13,
                headerTextAlign: 'left',
            }),
        },
    ];
};

const SwinburneReportTemplate = ({ data }) => {
    const styles = StyleSheet.create({
        body: {
            paddingTop: 30,
            paddingBottom: 30,
            paddingHorizontal: 35,
            marginBottom: 10,
        },
        section: {
            marginBottom: 10,
            padding: 15,
            display: 'flex',
            flexDirection: 'row',
        },
        mainTitle: {
            fontSize: 18,
            fontweight: 1000,
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 5,
        },
        catchmentTimes: {
            marginTop: 10,
            fontSize: 15,
            width: '100%',
            justifyContent: 'end',
            display: 'flex',
        },
    });
    return (
        <Page style={styles.body} wrap>
            <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />
            <View style={{ pageBreakAfter: 'always' }}>
                <Text style={styles.mainTitle}>OSD Design Summary Report</Text>
                <Text style={{ fontSize: 12, marginTop: 10 }}>
                    Method for OSD calculations: {data.osd.method.toUpperCase()}
                    {data.osd.qaMultiplicationFactor === 1 ? ' Standard Swinburne' : ' OSD4W'}
                </Text>
            </View>

            {/* Notes*/}
            {data.osd.qaMultiplicationFactor === 1 ? (
                <Text style={{ fontSize: 8, color: 'red', marginTop: 5 }}>
                    *Notes:{'\n'}
                    These calculations are performed using the Swinburne Method.
                    {'\n'}
                    They do not include the safety factor in OSD4W.{'\n'}
                    The results from this calculation may differ significantly compared to results
                    from OSD4W
                </Text>
            ) : (
                <Text></Text>
            )}

            {/* OSD System Specified */}
            <View>
                <Text style={{ fontSize: 10, marginTop: 10 }}>Device Name: {data.OSDCompany}</Text>
                <Text style={{ fontSize: 10, marginTop: 10 }}>
                    Device Type: {data.OSDMaterial} - {data.storageType}
                </Text>
            </View>

            {/* Site Details */}
            <View style={{ marginBottom: 50 }}>
                {generateAllTableData(data).map((table, index) => (
                    <>
                        {table.styles ? (
                            <Table
                                tableKey={index}
                                columnNames={table.columns}
                                tableData={table.items}
                                headerWidths={table.headerWidths}
                                cellWidths={table.cellWidths}
                                customCellWidths={table.customCellWidths}
                                tableStyles={table.styles}
                            />
                        ) : (
                            <></>
                        )}
                    </>
                ))}
            </View>

            <ReportFooter />
        </Page>
    );
};

export default SwinburneReportTemplate;
