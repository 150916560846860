import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    handleDeltaT,
    handleIFD,
    handleUserInput,
    handleClimateChange,
} from '../../../utils/redux/osdStateDTSlice';
import { osdState } from '../../../utils/redux/osdStateDTSlice';

import Tooltip from '../../common/Tooltip/Tooltip';

const IfdDataset = ({ setOsdResultData }) => {
    const dispatch = useDispatch();
    const state = useSelector(osdState);
    const [deltaT, setDeltaT] = useState(state.deltaT);
    const [userInputValue, setUserInputValue] = useState(state.climateChange);
    const [isUserInput, setIsUserInput] = useState(false);
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');

    const handleInputChange = (e) => {
        const selectedOption = e.target.options[e.target.selectedIndex];
        const value = selectedOption.value;
        const label = selectedOption.text;
        const group = selectedOption.parentNode.label;
        const color = selectedOption.getAttribute('data-color');

        if (value === 'userInput') {
            setIsUserInput(true);
            dispatch(handleUserInput(true));
            setDeltaT(null);
            dispatch(handleDeltaT(null));
            setSelectedColor(color || '');
        } else {
            setIsUserInput(false);
            dispatchDeltaT(value);
            dispatch(handleUserInput(false));
            setSelectedColor(color || '');
            setSelectedGroup(group);
        }

        // Set the concatenated label to be displayed
        dispatch(
            handleIFD({
                scenario: group,
                term: label,
            }),
        );
    };

    const handleUserInputChange = (e) => {
        const inputValue = parseFloat(e.target.value);
        setUserInputValue(inputValue);

        if (!isNaN(inputValue)) {
            setDeltaT(null);
            setOsdResultData(null);
            dispatch(handleDeltaT(null));
            dispatch(handleUserInput(true));
            dispatch(handleClimateChange(inputValue));
        }
    };

    const dispatchDeltaT = (selectedValue) => {
        const deltaTMap = {
            noChange: 0,
            currentSsp1: 1.2,
            mediumSsp1: 1.4,
            longSsp1: 1.5,
            currentSsp2: 1.2,
            mediumSsp2: 1.7,
            longSsp2: 2.4,
            currentSsp3: 1.2,
            mediumSsp3: 1.8,
            longSsp3: 3.3,
            currentSsp4: 1.3,
            mediumSsp4: 2.1,
            longSsp4: 4.1,
        };

        const newDeltaT = deltaTMap[selectedValue];

        if (newDeltaT !== undefined) {
            setDeltaT(newDeltaT);
            setOsdResultData(null);
            dispatch(handleDeltaT(newDeltaT));
        } else {
            console.warn(`No mapping found for selected value: ${selectedValue}`);
        }
    };
    //add yellow for user input and ssp's when showing value
    const dropdownOptions = [
        {
            label: 'Default',
            options: [
                {
                    label: '2016 IFD Data',
                    name: '2016 IFD Data',
                    value: 'noChange',
                    color: 'white',
                },
            ],
        },
        {
            label: 'User Specified Value',
            options: [
                {
                    label: 'Flat % increase as per Council Guidelines',
                    name: 'Flat % increase as per Council Guidelines',
                    value: 'userInput',
                    color: '#FFFF00',
                },
            ],
        },
        {
            label: 'SSP1-2.6',
            options: [
                {
                    label: 'Current and Near-term (2021-2040)',
                    name: 'SSP1-2.6 - Current and Near-term (2021-2040)',
                    value: 'currentSsp1',
                    color: '#ffcccc',
                },
                {
                    label: 'Medium-term (2041-2060)',
                    name: 'SSP1-2.6 - Medium-term (2041-2060)',
                    value: 'mediumSsp1',
                    color: '#ff9999',
                },
                {
                    label: 'Long-term (2060-2100)',
                    name: 'SSP1-2.6 - Long-term (2060-2100)',
                    value: 'longSsp1',
                    color: '#ff6666',
                },
            ],
        },
        {
            label: 'SSP2-4.5',
            options: [
                {
                    label: 'Current and Near-term (2021-2040)',
                    name: 'SSP2-4.5 - Current and Near-term (2021-2040)',
                    value: 'currentSsp2',
                    color: '#ff9999',
                },
                {
                    label: 'Medium-term (2041-2060)',
                    name: 'SSP2-4.5 - Medium-term (2041-2060)',
                    value: 'mediumSsp2',
                    color: '#ff6666',
                },
                {
                    label: 'Long-term (2060-2100)',
                    name: 'SSP2-4.5 - Long-term (2060-2100)',
                    value: 'longSsp2',
                    color: '#ff3333',
                },
            ],
        },
        {
            label: 'SSP3-7',
            options: [
                {
                    label: 'Current and Near-term (2021-2040)',
                    name: 'SSP3-7 - Current and Near-term (2021-2040)',
                    value: 'currentSsp3',
                    color: '#ff6666',
                },
                {
                    label: 'Medium-term (2041-2060)',
                    name: 'SSP3-7 - Medium-term (2041-2060)',
                    value: 'mediumSsp3',
                    color: '#ff3333',
                },
                {
                    label: 'Long-term (2060-2100)',
                    name: 'SSP3-7 - Long-term (2060-2100)',
                    value: 'longSsp3',
                    color: '#cc0000',
                },
            ],
        },
        {
            label: 'SSP4-8.5',
            options: [
                {
                    label: 'Current and Near-term (2021-2040)',
                    name: 'SSP4-8.5 - Current and Near-term (2021-2040)',
                    value: 'currentSsp4',
                    color: '#ff3333',
                },
                {
                    label: 'Medium-term (2041-2060)',
                    name: 'SSP4-8.5 - Medium-term (2041-2060)',
                    value: 'mediumSsp4',
                    color: '#cc0000',
                },
                {
                    label: 'Long-term (2060-2100)',
                    name: 'SSP4-8.5 - Long-term (2060-2100)',
                    value: 'longSsp4',
                    color: '#990000',
                },
            ],
        },
    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '25px', marginLeft: '56px' }}>
                <label
                    htmlFor="ifd-dataset"
                    style={{
                        whiteSpace: 'nowrap',
                        alignSelf: 'center',
                        marginRight: '1px',
                    }}
                >
                    IFD Dataset
                    <Tooltip link="https://docs.google.com/document/d/1opr9SXwyXENVKYHUpTM48_oC4zRoTy33/edit">
                        Select IFD data to be used in OSD calculations. The default is 2016 IFD from
                        BOM. Other options include climate change adjusted IFD based on climate
                        change scenarios as per procedure recommended in AR&R Book1, Chapter 6. For
                        more info, click on ?
                    </Tooltip>
                </label>
                <select
                    id="ifd-dataset"
                    onChange={handleInputChange}
                    style={{
                        paddingRight: '1rem',
                        paddingLeft: '1rem',
                        height: '3rem',
                        backgroundColor: selectedColor || '#fff',
                        borderRadius: '10px',
                        width: '22rem',
                    }}
                >
                    {dropdownOptions.map((group) => (
                        <optgroup
                            key={group.label}
                            label={group.label}
                            style={{
                                backgroundColor: '#f0f0f0',
                                color: '#666',
                                fontWeight: 'bold',
                            }}
                        >
                            {group.options.map((item) => (
                                <option
                                    key={item.value}
                                    value={item.value}
                                    data-color={item.color}
                                    style={{
                                        backgroundColor: '#fff',
                                        color: '#000',
                                    }}
                                >
                                    {item.label}
                                </option>
                            ))}
                        </optgroup>
                    ))}
                </select>
            </div>
            {!isUserInput && selectedGroup !== 'Default' && selectedGroup && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '12px',marginLeft: '55px' }}>
                    <label htmlFor="user-input" style={{ whiteSpace: 'nowrap' }}>
                        Climate Scenario:
                    </label>
                    <label
                        style={{
                            backgroundColor: selectedColor,
                            color: 'black',
                            padding: '5px 10px',
                            borderRadius: '10px',
                            height: '2.5rem',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {selectedGroup}
                    </label>
                </div>
            )}

            {isUserInput && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <label htmlFor="user-input" style={{ whiteSpace: 'nowrap' }}>
                        (%) Volume Increase
                        <Tooltip>
                            Input the % increase from the OSD Volume calculated with the 2016 IFD
                            data
                        </Tooltip>
                    </label>
                    <input
                        id="user-input"
                        type="number"
                        value={userInputValue}
                        onChange={handleUserInputChange}
                        style={{
                            paddingRight: '1rem',
                            paddingLeft: '1rem',
                            height: '3rem',
                            width: '120px',
                            borderRadius: '10px',
                            backgroundColor: selectedColor,
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default IfdDataset;
