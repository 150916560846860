// modules
import React, { useState, useEffect, useRef } from 'react';
import html2canvas from 'html2canvas';
import * as _ from 'lodash';
// utils
import { sails_api } from '../../../utils/globalConstant';
// components
import OsdsAds from '../osds-ads/OsdsAds';
import { assessOnsiteDetentionVolume, getCompanyDetails } from '../../../utils/osdUtils';
import { StorageTypeSelection } from '../StorageTypeSelection';
import PreDevAreas from '../components/PreDevAreas';
import PostDevAreas from '../components/PostDevAreas';
import MultiplicationDesignStormTC from '../components/MultiplicationDesignStormTC';
import Optional from '../components/Optional';
import CalculateOsdResultButton from '../components/CalculateOsdResultButton';
import OsdResultsTable from '../components/OsdResultTable';
import StormDurationsChart from '../components/StormDurationsChart';
import TCCalculatorModal from '../tc-calculator/TCCalculatorModal';
import OSDMethod from '../components/OSDMethod';
import { recordCalculateOSD } from '../../../utils/s3Utils';
import { useSelector } from 'react-redux';
import { osdState } from '../../../utils/redux/osdStateDTSlice';
function DefaultTemplate({
    AEPS,
    useOSDCompany,
    useStorageType,
    setOSDMaterial,
    userInfo,
    mapInfo,
    osdMethod,
    useOSDResultsData,
    templateData,
    setTemplateData,
    setChartGenerated,
    chartRef,
    savedModel,
}) {
    const isInitialMount = useRef(true);

    const [osdCompany, setOSDCompany] = useOSDCompany;
    const [storageType, setStorageType] = useStorageType;
    const [osdResultData, setOsdResultData] = useOSDResultsData;
    const state = useSelector(osdState);
    const [companyDetails, setCompanyDetails] = useState([]);
    /* show component */
    const [osdResultLoading, setOsdResultLoading] = useState(false);
    // global states
    const [globalInputs, setGlobalInputs] = useState({
        qaMultiplicationFactor: savedModel?.qaMultiplicationFactor ?? '2',
        aepPSD: savedModel?.aepPSD ?? 20,
        aepOSD: savedModel?.aepOSD ?? 10,
        catchmentTimeTC: savedModel?.catchmentTimeTc ?? 20,
        catchmentTimeTSO: savedModel?.catchmentTimeTso ?? 10,
        catchmentTimeTCS: savedModel?.catchmentTimeTcs ?? 10,
    });
    const [showTCCalculator, setShowTCCalculator] = useState('');
    const [tcCalculatorData, setTcCalculatorData] = useState(
        savedModel?.tcData ?? { catchmentTimeTCpre: null, catchmentTimeTSOpost: null },
    );
    // Update the flag based on conditions
    const [councilPSD, setCouncilPSD] = useState(savedModel?.councilPSD ?? '');
    const [preDevAreas, setPreDevAreas] = useState(
        savedModel?.preDevAreas?.areas ?? [
            {
                id: 0,
                areaName: '',
                surfaceArea: '',
                runoffCoefficient: '',
                impervious: true,
            },
        ],
    );

    // Post-dev Areas
    const [postDevAreas, setPostDevAreas] = useState(
        savedModel?.postDevAreas?.areas ?? [
            {
                id: 0,
                areaName: '',
                surfaceArea: '',
                runoffCoefficient: '',
                surfaceType: 'Paved',
                uncontrolled: '0',
                impervious: true,
            },
        ],
    );

    // OSD4w Form Inputs
    const [useOsd4wForm, setUseOsd4wForm] = useState(false);
    const [osd4wTd, setOsd4wTd] = useState('');
    const [osd4wStorageIntensity, setOsd4wStorageIntensity] = useState('');
    const [osd4wFlowIntensity, setOsd4wFlowIntensity] = useState('');

    // Optional Inputs
    const [climateChangeRate, setClimateChangeRate] = useState(savedModel?.climateChangeRate ?? '');
    useEffect(() => {
        if (state.userInput === true) {
            setClimateChangeRate(state.climateChange);
        } else {
            setClimateChangeRate(0);
        }
    }, [state.userInput, state.deltaT, state.climateChange]);
    const [tankHeight, setTankHeight] = useState(savedModel?.tankHeight ?? '');

    /* Calculated Data */
    const sumPreDevAreas = preDevAreas.reduce((acc, area) => acc + +area.surfaceArea, 0);
    const sumPostDevAreas = postDevAreas.reduce((acc, area) => acc + +area.surfaceArea, 0);
    const sumUncontrolledAreas = postDevAreas.reduce((acc, area) => acc + +area.uncontrolled, 0);

    const averagePreROC =
        preDevAreas.reduce((acc, area) => acc + +area.surfaceArea * area.runoffCoefficient, 0) /
        sumPreDevAreas;
    const averagePostROC =
        postDevAreas.reduce((acc, area) => acc + +area.surfaceArea * area.runoffCoefficient, 0) /
        sumPostDevAreas;
    const averagePostUncontrolledROC =
        postDevAreas.reduce((acc, area) => acc + +area.surfaceArea * area.runoffCoefficient, 0) /
        sumUncontrolledAreas;

    useEffect(() => {
        if (storageType !== '') {
            getCompanyDetails(storageType, mapInfo.rainfallStation).then((newCompanyDetails) => {
                if (!newCompanyDetails.length) {
                    setStorageType('N/A');
                    setOSDMaterial('N/A');
                }
                setCompanyDetails(newCompanyDetails);
            });
        }
    }, [storageType]);

    useEffect(() => {
        setGlobalInputs((prev) => ({
            ...prev,
            catchmentTimeTC: +prev.catchmentTimeTCS + +prev.catchmentTimeTSO,
        }));
    }, [globalInputs.catchmentTimeTCS, globalInputs.catchmentTimeTSO]);

    // click on CALCULATE  OSD button
    const computeOSDResults = async () => {
        setOsdResultLoading(true);
        setTemplateData(null);
        setOsdResultData(null);
        setChartGenerated(false);

        const storageTypes = ['Above ground storage', 'Below ground pipe', 'Below ground tank'];
        const osdRoute = '/OSD/osdResults/Swinburne';
        let buf;
        let message;

        await Promise.all(
            storageTypes.map(async (item) => {
                const osdPayload = {
                    method: osdMethod + ' Method',
                    psd: councilPSD, // this is used to show if we are using council PSD
                    councilPSD: councilPSD,
                    preDevAreas,
                    preDevImpArea: sumPreDevAreas,
                    preDevImpAreaRoC: averagePreROC,
                    preDevPArea: 0,
                    preDevPAreaRoC: 0,
                    postDevAreas,
                    postDevImpArea: sumPostDevAreas,
                    postDevImpAreaRoC: averagePostROC ?? 0,
                    postUncontrolledROC: averagePostUncontrolledROC ?? 0,
                    postDevPArea: 0,
                    postDevPAreaRoC: 0,
                    uncontrolledImpArea: sumUncontrolledAreas,
                    uncontrolledPArea: 0,
                    catchmentTimeTc: globalInputs.catchmentTimeTC,
                    catchmentTimeTso: globalInputs.catchmentTimeTSO,
                    catchmentTimeTcs: globalInputs.catchmentTimeTCS,
                    tankHeight: tankHeight,
                    aepPSD: globalInputs.aepPSD,
                    aepOSD: globalInputs.aepOSD,
                    storageType: item,
                    climateAdjustment: 1 + climateChangeRate / 100,
                    qaMultiplicationFactor: globalInputs.qaMultiplicationFactor,
                    osd4wTd: useOsd4wForm ? osd4wTd : '',
                    osd4wFlowIntensity: useOsd4wForm ? osd4wFlowIntensity : '',
                    osd4wStorageIntensity: useOsd4wForm ? osd4wStorageIntensity : '',
                    deltaT: state.deltaT,
                };

                try {
                    const res = await sails_api.post(osdRoute, {
                        latitude: mapInfo.coordinate.lat,
                        longitude: mapInfo.coordinate.lng,
                        osd: osdPayload,
                        totalImpArea: +sumPostDevAreas,
                        totalPArea: 0,
                    });

                    if (res.data.message) {
                        message = res.data.message;
                    } else if (item === 'Above ground storage')
                        buf = { ...buf, above_ground_storage: res.data };
                    else if (item === 'Below ground pipe')
                        buf = { ...buf, below_ground_pipe: res.data };
                    else if (item === 'Below ground tank')
                        buf = { ...buf, below_ground_tank: res.data };
                } catch (err) {
                    console.log(err);
                    alert('Computing OSD result error!');
                    setOsdResultLoading(false);
                }
            }),
        );

        const formattedStorageType = storageType.toLowerCase().replace(/ /g, '_');
        recordCalculateOSD({
            name: userInfo.assessorName,
            email: userInfo.assessorEmail,
            coordinates: `${mapInfo.coordinate.lat}, ${mapInfo.coordinate.lng}`,
            catchment_area: sumPostDevAreas,
            council: mapInfo.council,
            address: mapInfo.address,
            storage_type: storageType,
            tank_specified: osdCompany,
            psd: buf[formattedStorageType].PSD,
            volume: buf[formattedStorageType].onSiteDetentionVolume,
            height_above_orifice: tankHeight,
            orifice_diameter: buf[formattedStorageType].orificeDiameter,
        });

        if (message) alert(message);
        else if (buf && councilPSD) {
            const result = assessOnsiteDetentionVolume(buf, councilPSD);
            setOsdResultData({ data: result });
        } else if (buf && !councilPSD) {
            setOsdResultData({ data: buf });
        }

        /* Generate Storm Duration Chart */
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        let chartDataBuf;
        if (chartRef.current) {
            await delay(3000); // Add a delay of 1500ms to ensure the chart is fully rendered
            await html2canvas(chartRef.current)
                .then((canvas) => {
                    const image = canvas.toDataURL('image/png');
                    chartDataBuf = image;
                    setChartGenerated(true);
                })
                .catch((error) => {
                    console.error('Error capturing chart as image:', error);
                });
        } else console.error('chartRef is null');

        setOsdResultLoading(false);
        setTemplateData({
            OSDs4VIPs: true,
            method: osdMethod + ' Method',
            psd: councilPSD, // this is used to show if we are using council PSD
            councilPSD: councilPSD,
            preDevAreas: {
                areas: preDevAreas,
            },
            postDevAreas: {
                areas: postDevAreas,
            },
            catchmentTimeTc: globalInputs.catchmentTimeTC,
            catchmentTimeTso: globalInputs.catchmentTimeTSO,
            catchmentTimeTcs: globalInputs.catchmentTimeTCS,
            tankHeight: tankHeight,
            aepPSD: globalInputs.aepPSD,
            aepOSD: globalInputs.aepOSD,
            climateAdjustment: 1 + climateChangeRate / 100,
            climateChangeRate,
            qaMultiplicationFactor: globalInputs.qaMultiplicationFactor,
            sumPreDevAreas: sumPreDevAreas,
            sumPostDevAreas: sumPostDevAreas,
            sumUncontrolledAreas: sumUncontrolledAreas,
            tDurChart: chartDataBuf,
            tcData: tcCalculatorData,
            ifd: state.ifd,
            deltaT: state.deltaT,
        });
    };

    const downloadChartAsImage = () => {
        const link = document.createElement('a');
        link.href = templateData.tDurChart;
        link.download = 'storm_durations_chart.png';
        link.click();
    };

    /* Hide the OSD result table if the user changed any input */
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }
        const resetOSDData = () => {
            setOsdResultData(null);
            setTemplateData(null);
        };

        resetOSDData();
    }, [
        globalInputs,
        councilPSD,
        preDevAreas,
        postDevAreas,
        climateChangeRate,
        tankHeight,
        osd4wTd,
        osd4wStorageIntensity,
        osd4wFlowIntensity,
    ]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <div style={{ width: '75%' }}>
                    {' '}
                    {/* Set width for OSDMethod */}
                    <OSDMethod method={globalInputs} setMethod={setGlobalInputs} />
                </div>
            </div>
            <br />
            <MultiplicationDesignStormTC
                globalInputs={globalInputs}
                setGlobalInputs={setGlobalInputs}
                AEPS={AEPS}
                setShowTCCalculator={setShowTCCalculator}
                setTcCalculatorData={setTcCalculatorData}
            />
            <br />
            {/* Pre-Development Areas */}
            <PreDevAreas
                preDevAreas={preDevAreas}
                setPreDevAreas={setPreDevAreas}
                TC={{ psd: councilPSD }}
                setTC={(value) => setCouncilPSD(value.psd)}
                sumPreDevAreas={sumPreDevAreas}
                sumPostDevAreas={sumPostDevAreas}
                options={{
                    showRunoffCoefficient: true,
                    hideImperviousness: true,
                    hideCy: true,
                }}
            />
            <br />
            {/* Post-Development Areas */}
            <PostDevAreas
                postDevAreas={postDevAreas}
                setPostDevAreas={setPostDevAreas}
                sumPreDevAreas={sumPreDevAreas}
                sumPostDevAreas={sumPostDevAreas}
                options={{
                    showRunoffCoefficient: true,
                    hideImperviousness: true,
                    hideCy: true,
                }}
            />
            <br />
            {/* Osd4wForm */}
            {/* {osdMethod === 'Swinburne' && +globalInputs.qaMultiplicationFactor === 2 && (
                <>
                    <Osd4wForm
                        useOsd4wForm={useOsd4wForm}
                        setUseOsd4wForm={setUseOsd4wForm}
                        osd4wTd={osd4wTd}
                        setOsd4wTd={setOsd4wTd}
                        osd4wStorageIntensity={osd4wStorageIntensity}
                        setOsd4wStorageIntensity={setOsd4wStorageIntensity}
                        osd4wFlowIntensity={osd4wFlowIntensity}
                        setOsd4wFlowIntensity={setOsd4wFlowIntensity}
                    />
                    <br />
                </>
            )} */}
            {/* optional */}
            <Optional
                climateChangeRate={climateChangeRate}
                setClimateChangeRateChange={setClimateChangeRate}
                tankHeight={tankHeight}
                setTankHeight={setTankHeight}
            />
            <br />
            <StorageTypeSelection setStorageType={setStorageType} />
            {/* OSD Material */}
            {storageType && companyDetails && (
                <>
                    <br />
                    <OsdsAds
                        companyDetails={companyDetails}
                        setOSDMaterial={setOSDMaterial}
                        osdCompany={osdCompany}
                        setOSDCompany={setOSDCompany}
                        storageType={storageType}
                    />
                </>
            )}
            <br />
            {/* Calculate OSD Result Button */}
            <CalculateOsdResultButton
                TC={{
                    pre: globalInputs.catchmentTimeTC,
                    post: globalInputs.catchmentTimeTSO,
                    uncontrolled: globalInputs.catchmentTimeTCS,
                    psd: councilPSD,
                }}
                preDevAreas={preDevAreas}
                postDevAreas={postDevAreas}
                tankHeight={tankHeight}
                storageType={storageType}
                osdCompany={osdCompany}
                computeOSDResults={computeOSDResults}
                osdResultLoading={osdResultLoading}
                disabled={
                    (!councilPSD && +sumPreDevAreas - +sumPostDevAreas !== 0) ||
                    (tankHeight !== '' && +tankHeight === 0)
                }
                userInfo={userInfo}
            />
            <br />

            {/* Results Table */}
            {osdResultData && (
                <>
                    <OsdResultsTable
                        preDevDesignStorm={globalInputs.aepPSD}
                        postDevDesignStorm={globalInputs.aepOSD}
                        tableData={{
                            aboveGroundStorage: {
                                permissibleDischarge: councilPSD
                                    ? councilPSD
                                    : parseFloat(
                                          osdResultData.data.above_ground_storage.PSD,
                                      ).toFixed(2),
                                onSiteDetentionVolume: (
                                    osdResultData.data.above_ground_storage.onSiteDetentionVolume *
                                    (1 + climateChangeRate / 100)
                                ).toFixed(2),
                                orificeDiameter:
                                    osdResultData.data.above_ground_storage.orificeDiameter !==
                                    'N/A'
                                        ? parseFloat(
                                              osdResultData.data.above_ground_storage
                                                  .orificeDiameter,
                                          ).toFixed(2) + ' mm'
                                        : 'N/A',
                            },
                            belowGroundTank: {
                                permissibleDischarge: councilPSD
                                    ? councilPSD
                                    : parseFloat(osdResultData.data.below_ground_tank.PSD).toFixed(
                                          2,
                                      ),
                                onSiteDetentionVolume: (
                                    osdResultData.data.below_ground_tank.onSiteDetentionVolume *
                                    (1 + climateChangeRate / 100)
                                ).toFixed(2),
                                orificeDiameter:
                                    osdResultData.data.below_ground_tank.orificeDiameter !== 'N/A'
                                        ? parseFloat(
                                              osdResultData.data.below_ground_tank.orificeDiameter,
                                          ).toFixed(2) + ' mm'
                                        : 'N/A',
                            },
                            belowGroundPipe: {
                                permissibleDischarge: councilPSD
                                    ? councilPSD
                                    : parseFloat(osdResultData.data.below_ground_pipe.PSD).toFixed(
                                          2,
                                      ),
                                onSiteDetentionVolume: (
                                    osdResultData.data.below_ground_pipe.onSiteDetentionVolume *
                                    (1 + climateChangeRate / 100)
                                ).toFixed(2),
                                orificeDiameter:
                                    osdResultData.data.below_ground_pipe.orificeDiameter !== 'N/A'
                                        ? parseFloat(
                                              osdResultData.data.below_ground_pipe.orificeDiameter,
                                          ).toFixed(2) + ' mm'
                                        : 'N/A',
                            },
                        }}
                    />
                    <br />
                    <StormDurationsChart
                        chartRef={chartRef}
                        osdResultData={
                            osdResultData.data[storageType.toLowerCase().replace(/\s+/g, '_')] ?? {}
                        }
                        downloadChartAsImage={downloadChartAsImage}
                    />
                    <br />
                </>
            )}
            <TCCalculatorModal
                type={showTCCalculator}
                tcCalculatorData={tcCalculatorData[showTCCalculator]}
                onClose={() => setShowTCCalculator('')}
                onDone={(tcCalculatorData, finalResult) => {
                    setGlobalInputs((prev) => ({ ...prev, [showTCCalculator]: finalResult }));
                    setTcCalculatorData((prev) => ({
                        ...prev,
                        [showTCCalculator]: tcCalculatorData,
                    }));
                }}
            />
        </>
    );
}

export default DefaultTemplate;
